<h2 class="tw-font-bold tw-mb-6">Update Master</h2>
<button (click)="closeModal()" class="btn-close absolute"></button>
<form [formGroup]="uploadMasterForm">
  <div class="tw-grid tw-grid-cols-2 tw-gap-4 mb-3">
    <div>
      <label>Master Name </label>
      <p class="tw-text-sm">{{ master?.masterName }}</p>
    </div>
    <div>
      <label class="mb-2">Overwrite Existing Data? </label>
      <div class="flex-inline">
        <label class="radio">
          <input class="form-check-input" type="radio" formControlName="isOverrideFile" value="true" />
          Yes
        </label>
        <label class="radio">
          <input class="form-check-input" type="radio" formControlName="isOverrideFile" value="false" />
          No
        </label>
      </div>
    </div>
  </div>

  <div>
    <label class="form-label tw-mt-4">Select File (CSV)</label>
    <input class="form-control" type="file" formControlName="inputFile" accept=".csv" (change)="onFileChange($event)" />
  </div>

  <button [disabled]="uploadMasterForm.invalid" (click)="uploadData()" class="btn btn-primary mt-4 !tw-px-5">
    Update Master
  </button>
</form>
