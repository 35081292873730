import { Injectable } from '@angular/core';
import OrgResp, {
  MasterResp,
  WorkflowHistoryResp,
  CreateWorkFlowResp,
  Purpose,
  WorkflowIOResponse,
  InputHeaders,
  DuplicateCheckForm,
  DuplicateCheckFormResp,
  DataMergeForm,
  DataMergeResponse,
  TaskResponse,
  TaskSequence,
  WorkflowTemplate,
  EditWorkflowResponse,
  CopyWorkflowResponse,
  TransformationHistoryResponse,
  MappedOutputHeader,
  AddtaskErrorHandeling,
  ExecuteResponse,
  CalculateValueForm,
  AddCalculateValueResponse,
  TaskError,
  AggregationValueForm,
  PayloadMaptoFinal,
  PayloadTestRule,
} from '@app/types/garnet.type';

import { MultiSelectOption } from '@app/shared/multi-select/multi-select.component';
import { ApiResp } from '@app/types/global.type';

import { BehaviorSubject, Observable, catchError, map, of, tap } from 'rxjs';

import { downloadBlob } from '@app/utils/misc';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class WorkflowService extends BaseService {
  private editMode = new BehaviorSubject<boolean>(true);
  editDetails$ = this.editMode.asObservable();

  // To hold flag
  private copyWorkflow = new BehaviorSubject<boolean>(false);
  copyWorkflow$ = this.copyWorkflow.asObservable();

  //To hold Workflow Item
  private cpWorkflowSubject = new BehaviorSubject<WorkflowHistoryResp>({} as WorkflowHistoryResp);
  cpWorkflow$ = this.cpWorkflowSubject.asObservable();

  setCopiedWorkflow(workflowItem: WorkflowHistoryResp): void {
    this.cpWorkflowSubject.next(workflowItem);
  }

  // inputHeaders: InputHeader = {};
  orgs: OrgResp[] = [];

  private taskDetailsSubject = new BehaviorSubject<EditWorkflowResponse[]>([]);
  taskDetails$ = this.taskDetailsSubject.asObservable();

  // Method to update the task details
  setTasksDetails(taskDetails: EditWorkflowResponse[]): void {
    this.taskDetailsSubject.next(taskDetails);
  }

  setEditMode(flag: boolean) {
    this.editMode.next(flag);
  }

  setCopyWorkflow(flag: boolean) {
    this.copyWorkflow.next(flag);
  }

  // Method to get the current value of task details
  getTasksDetails(): EditWorkflowResponse[] {
    return this.taskDetailsSubject.getValue();
  }

  private cache = new Map<string, Observable<MasterResp[]>>();

  //Fetching Organizational Data (DONE)
  fetchOrganizations(refresh: boolean = false): Observable<OrgResp[]> {
    if (this.orgs.length && !refresh) return of(this.orgs);

    return this.httpClient.get<ApiResp>('/myportal/org/details').pipe(
      map((resp) => {
        if (resp.status) return (this.orgs = resp.response);
        this.toastr.error(resp.message ?? 'Error');
        return null;
      }),
    );
  }

  //Fetching Workflow Hisroty (DONE)
  fetchWorkflowHistory(): Observable<WorkflowHistoryResp[]> {
    return this.httpClient.get<ApiResp>('/mygarnet/workflow/template/history').pipe(
      map((resp) => {
        if (resp.status) return resp.response;
        this.toastr.error(resp.message ?? 'Error');
        return null;
      }),
    );
  }

  //Create Workflow (DONE)
  createWorkflow(payload: WorkflowTemplate): Observable<CreateWorkFlowResp> {
    return this.httpClient.put<ApiResp>('/mygarnet/workflow/template/add', payload).pipe(
      map((resp) => {
        if (resp.status) return resp.response;
        this.toastr.error(resp.message ?? 'Error');
        return null;
      }),
    );
  }

  // Getting ouput purposes in IO (DONE)
  getOutputPurposes(): Observable<Purpose[]> {
    return this.httpClient.get<ApiResp>('/mygarnet/workflow/template/output/purpose').pipe(
      map((resp) => {
        if (resp.status) return resp.response;
        this.toastr.error(resp.message ?? 'Error');
        return null;
      }),
    );
  }

  // IO Form
  addInputOutput(payload: FormData): Observable<WorkflowIOResponse> {
    return this.httpClient
      .post<ApiResp>('/mygarnet/workflow/template/add/inputOutput', payload)
      .pipe(
        map((resp) => {
          if (resp.status) return resp.response;
          this.toastr.error(resp.message ?? 'Error');
          return null;
        }),
      );
  }

  // Transformation Logic for InputHeaders
  transformHeaders(input: InputHeaders): MultiSelectOption {
    return {
      label: input.colName,
      value: input.colNo,
      checked: false,
    };
  }

  transformOrg(input: { orgId: number; orgName: string }): MultiSelectOption {
    return {
      label: input.orgName,
      value: input.orgId.toString(),
      checked: false,
    };
  }

  getInputHeaders(workflowId: string, taskSequenceNo: number): Observable<InputHeaders[]> {
    const params = { workflowId, taskSequenceNo };
    return this.httpClient
      .get<ApiResp>('/mygarnet/workflow/template/input/headers', { params })
      .pipe(
        map((resp) => {
          if (resp.status) {
            return resp.response as InputHeaders[];
          } else {
            throw new Error(resp.message ?? 'Error');
          }
        }),
        catchError((error) => {
          this.toastr.error(error.message ?? 'Error');
          return of([]); // Returning null in case of error
        }),
      );
  }

  //Duplicate Check Form
  addDuplicateCheck(payload: DuplicateCheckForm): Observable<DuplicateCheckFormResp> {
    return this.httpClient
      .post<ApiResp>('/mygarnet/workflow/template/add/taskDetails', payload)
      .pipe(
        map((resp) => {
          if (resp.status) return resp.response;
          this.toastr.error(resp.message ?? 'Error');
          return null;
        }),
      );
  }

  getMasterNames(workflowId: string, masterType: string): Observable<MasterResp[]> {
    const cacheKey = `${workflowId}-${masterType}`;
    if (this.cache.has(cacheKey)) {
      return this.cache.get(cacheKey)!;
    }

    const params = { workflowId, masterType };
    const response$ = this.httpClient
      .get<ApiResp>('/mygarnet/workflow/template/master/names', { params })
      .pipe(
        map((resp) => {
          if (resp.status) return resp.response;
          this.toastr.error(resp.message ?? 'Error');
          return null;
        }),
        tap((data) => {
          if (data) {
            this.cache.set(cacheKey, of(data)); // Cache the response
          }
        }),
      );

    this.cache.set(cacheKey, response$);
    return response$;
  }

  getMasterHeaders(
    workflowId: string,
    masterType: string,
    masterName: string,
    masterId: number,
  ): Observable<InputHeaders[]> {
    const params = { workflowId, masterType, masterName, masterId };
    return this.httpClient
      .get<ApiResp>('/mygarnet/workflow/template/master/headers', { params })
      .pipe(
        map((resp) => {
          if (resp.status) return resp.response;
          this.toastr.error(resp.message ?? 'Error');
          return null;
        }),
      );
  }

  getMasterHeadersByUploadFile(payload: FormData): Observable<{ masterId: number }> {
    return this.httpClient.post<ApiResp>('/mygarnet/workflow/template/upload/master', payload).pipe(
      map((resp) => {
        if (resp.status) return resp.response;
        this.toastr.error(resp.message ?? 'Error');
        return null;
      }),
    );
  }

  dataMerge(payload: DataMergeForm): Observable<DataMergeResponse> {
    return this.httpClient
      .post<ApiResp>('/mygarnet/workflow/template/add/taskDetails', payload)
      .pipe(
        map((resp) => {
          if (resp.status) return resp.response;
          this.toastr.error(resp.message ?? 'Error');
          return null;
        }),
      );
  }

  saveTaskSequence(workflowId: string, taskDetails: TaskSequence[]): Observable<TaskResponse> {
    const params = { workflowId, taskDetails };
    return this.httpClient
      .post<ApiResp>('/mygarnet/workflow/template/add/task/sequence', params)
      .pipe(
        map((resp) => {
          if (resp.status) return resp.response;
          this.toastr.error(resp.message ?? 'Error');
          return null;
        }),
      );
  }

  deleteWorkflow(workflowIds: number): Observable<boolean> {
    return this.httpClient
      .delete<ApiResp>('/mygarnet/workflow/template/delete', { params: { workflowIds } })
      .pipe(
        map((resp) => {
          if (resp.status) return resp.response;
          this.toastr.error(resp.message ?? 'Error');
          return null;
        }),
      );
  }

  getTaskDetails(workflowId: string): Observable<EditWorkflowResponse[]> {
    const params = { workflowId };
    return this.httpClient.get<ApiResp>('/mygarnet/workflow/template/taskDetails', { params }).pipe(
      map((resp) => {
        if (resp.status) {
          return resp.response;
        }
        this.toastr.error(resp.message ?? 'Error');
        return null;
      }),
    );
  }

  addCopyWorkflow(payload: {
    workflowId: string;
    orgIds: (string | number)[];
  }): Observable<CopyWorkflowResponse> {
    return this.httpClient.post<ApiResp>('/mygarnet/workflow/template/copy', payload).pipe(
      map((resp) => {
        if (resp.status) return resp.response;
        this.toastr.error(resp.message ?? 'Error');
        return null;
      }),
    );
  }

  //=================Transform ===============================//

  addGenerateOutput(payload: FormData): Observable<{
    message: string;
    workflowId: number;
  }> {
    return this.httpClient.post<ApiResp>('/mygarnet/generate/output/process/file', payload).pipe(
      map((resp) => {
        if (resp.status) return resp.response;
        this.toastr.error(resp.message ?? 'Error');
        return null;
      }),
    );
  }

  getTranformedHistory(payload: {
    page: number;
    size: number;
    toDate: string;
    fromDate: string;
  }): Observable<TransformationHistoryResponse[]> {
    const params = payload;
    return this.httpClient.get<ApiResp>('/mygarnet/generate/output/history', { params }).pipe(
      map((resp) => {
        if (resp.status) return resp.response.records;
        this.toastr.error(resp.message ?? 'Error');
        return null;
      }),
    );
  }

  getProcessDetail(docId: string): Observable<TaskError[]> {
    const params = { docId };
    return this.httpClient.get<ApiResp>('/mygarnet/generate/output/getTaskDetail', { params }).pipe(
      map((resp) => {
        if (resp.status) return resp.response;
        this.toastr.error(resp.message ?? 'Error');
        return null;
      }),
    );
  }

  downloadTransformedFile(docId: string) {
    const params = { docId };
    return this.httpClient
      .get('/mygarnet/generate/output/download/result', { params, responseType: 'blob' })
      .pipe(map((resp) => downloadBlob(resp, 'application/csv', 'Transform Errors.csv')));
  }

  //=================END Transform ===============================//

  getMapInputHeaders(workflowId: string): Observable<InputHeaders[]> {
    const params = { workflowId };
    return this.httpClient
      .get<ApiResp>('/mygarnet/workflow/template/mapToFinal/inputDetails', { params })
      .pipe(
        map((resp) => {
          if (resp.status) return resp.response;
          this.toastr.error(resp.message ?? 'Error');
          return null;
        }),
      );
  }

  getMapOutputHeaders(workflowId: string): Observable<MappedOutputHeader> {
    const params = { workflowId };
    return this.httpClient
      .get<ApiResp>('/mygarnet/workflow/template/mapToFinal/outputDetails', { params })
      .pipe(
        map((resp) => {
          if (resp.status) return resp.response;
          this.toastr.error(resp.message ?? 'Error');
          return null;
        }),
      );
  }

  //==============Submit workflow=========================//

  submitWorkflow(workflowId: string): Observable<{
    workflowId: number;
  }> {
    const params = { workflowId };
    return this.httpClient.post<ApiResp>('/mygarnet/workflow/template/submit', params).pipe(
      map((resp) => {
        if (resp.status) return resp.response;
        this.toastr.error(resp.message ?? 'Error');
        return null;
      }),
    );
  }

  getWorkFlowDetails(workflowId: string) {
    const params = { workflowId };
    return this.httpClient.get<ApiResp>('/mygarnet/workflow/template/details', { params }).pipe(
      map((resp) => {
        if (resp.status) return resp.response;
      }),
    );
  }

  //==============Submit workflow=========================//

  //==============Error Handeling=================//

  addtask(payload: AddtaskErrorHandeling) {
    return this.httpClient
      .post<ApiResp>('/mygarnet/workflow/template/add/errorHandlingTask', payload)
      .pipe(
        map((resp) => {
          if (resp.status) return resp.status;
          this.toastr.error(resp.message ?? 'Error');
          return null;
        }),
      );
  }

  getErrorHandelingView(workflowId: string) {
    const params = { workflowId };
    return this.httpClient
      .get<ApiResp>('/mygarnet/workflow/template/get/errorHandlingTask?', { params })
      .pipe(
        map((resp) => {
          if (resp.status) return resp.response.errorHandling;
        }),
      );
  }

  ExecuteTest(payload: FormData): Observable<ExecuteResponse> {
    return this.httpClient.post<ApiResp>('/mygarnet/test/taskDetails', payload).pipe(
      map((resp) => {
        if (resp.status) return resp.response;
        this.toastr.error(resp.message ?? 'Error');
        return null;
      }),
    );
  }

  getTestDetails(testWorkflowId: number) {
    const params = { testWorkflowId };
    return this.httpClient.get<ApiResp>('/mygarnet/test/getTaskDetail', { params }).pipe(
      map((resp) => {
        if (resp.status) return resp.response;
        this.toastr.error(resp.message ?? 'Error');
        return null;
      }),
    );
  }

  downloadTestDetails(docId: string) {
    const params = { docId };
    return this.httpClient
      .get('/mygarnet/test/download/result', { params, responseType: 'blob' })
      .pipe(map((resp) => downloadBlob(resp, 'application/csv', 'TestErrors.csv')));
  }

  addCalculateValues(payload: CalculateValueForm): Observable<AddCalculateValueResponse> {
    return this.httpClient
      .post<ApiResp>('/mygarnet/workflow/template/add/taskDetails', payload)
      .pipe(
        map((resp) => {
          if (resp.status) return resp.response;
          this.toastr.error(resp.message ?? 'Error');
          return null;
        }),
      );
  }

  getTaskInformation(workflowId: string) {
    const params = { workflowId };
    return this.httpClient
      .get<ApiResp>('/mygarnet/workflow/template/defineWorkflow/taskDetails', { params })
      .pipe(
        map((resp) => {
          if (resp.status) return resp.response;
          this.toastr.error(resp.message ?? 'Error');
          return null;
        }),
      );
  }

  addAggregationValues(payload: AggregationValueForm) {
    return this.httpClient
      .post<ApiResp>('/mygarnet/workflow/template/add/taskDetails', payload)
      .pipe(
        map((resp) => {
          if (resp.status) return resp.response;
          this.toastr.error(resp.message ?? 'Error');
          return null;
        }),
      );
  }

  getMapToFInalData(workflowId: string) {
    const params = { workflowId };
    return this.httpClient
      .get<ApiResp>('/mygarnet/workflow/template/mapToFinal/get', { params })
      .pipe(
        map((resp) => {
          if (resp.status) return resp.response;
          this.toastr.error(resp.message ?? 'Error');
          return null;
        }),
      );
  }

  saveMaptoFinalData(payload: PayloadMaptoFinal) {
    return this.httpClient
      .post<ApiResp>('/mygarnet/workflow/template/mapToFinal/add', payload)
      .pipe(
        map((resp) => {
          return this.handleFormResp(resp);
        }),
      );
  }

  testMaptoFInalRule(payload: PayloadTestRule) {
    return this.httpClient
      .post<ApiResp>('/mygarnet/workflow/template/mapToFinal/testRule', payload)
      .pipe(
        map((resp) => {
          return this.handleFormResp(resp);
        }),
      );
  }

  getMasterDetails() {
    return this.httpClient.get<ApiResp>('/mygarnet/workflow/template/master/details').pipe(
      map((resp) => {
        return this.handleFormResp(resp);
      }),
    );
  }

  getMasterStatus(masterId: string) {
    const params = { masterId };
    return this.httpClient
      .get<ApiResp>('/mygarnet/workflow/template/master/update/status', { params })
      .pipe(
        map((resp) => {
          return this.handleFormResp(resp);
        }),
      );
  }

  UpdateMaster(payload: FormData) {
    return this.httpClient.post<ApiResp>('/mygarnet/workflow/template/master/update', payload).pipe(
      map((resp) => {
        return this.handleFormResp(resp);
      }),
    );
  }

  downloadMaster(masterId: string) {
    const params = { masterId };
    return this.httpClient
      .get<ApiResp>('/mygarnet/workflow/template/master/update/download', { params })
      .pipe(
        map((resp) => {
          if (resp.status) return resp.response.url;
          this.toastr.error(resp.message ?? 'Error');
          return resp;
        }),
      );
  }
}

export { Purpose };
