import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { WorkflowService } from '@app/services/workflow.service';
import { Master } from '@app/types/garnet.type';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgIconComponent } from '@ng-icons/core';

import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-master-upload',
  standalone: true,
  imports: [NgIconComponent, DatePipe, ReactiveFormsModule],
  templateUrl: './master-upload.component.html',
  styleUrl: './master-upload.component.css',
})
export class MasterUploadComponent {
  @Input() workflowId!: string;
  @Input() master!: Master | null;
  @Output() refreshList = new EventEmitter<void>();

  uploadMasterForm = new FormGroup({
    isOverrideFile: new FormControl('false'),
    inputFile: new FormControl(null, [Validators.required]),
  });

  file!: File | null;

  statusInfo = {
    status: 'INPROGRESS', // Default status
    lastUpdated: null as string | Date | null, // Initially no last updated date
  };

  constructor(
    private workflowSvc: WorkflowService,
    private toastr: ToastrService,
    private modal: NgbModal,
  ) {}

  onFileChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files[0]) {
      this.file = inputElement.files[0];
    }
  }

  uploadData(): void {
    if (this.uploadMasterForm.valid && this.file) {
      const formData = new FormData();

      if (this.master) {
        formData.append('masterId', this.master.masterId);
        formData.append('masterName', this.master.masterName);
      }

      formData.append('inputFile', this.file);

      const isOverrideFile = this.uploadMasterForm?.get('isOverrideFile')?.value;
      if (isOverrideFile) {
        formData.append('isOverrideFile', isOverrideFile);
      }

      this.workflowSvc.UpdateMaster(formData).subscribe((resp) => {
        if (resp) {
          const message = resp.message;
          this.toastr.success(message);
          this.refreshList.emit();
        }
      });
    }
  }

  closeModal() {
    this.modal.dismissAll();
  }
}
